import React from 'react'
import { Tab, Row, Col, Nav, Alert } from 'react-bootstrap'
import Calender from './Calender'
import Statistics from './Statistics'
import StudentList from './tabItems/StudentList'

class CourseInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      crn: props.crn,
      name: ""
    }

    this.getName = this.getName.bind(this)
    this.getName()
  }

  getName() {
    var got = require("got")
    const url = "http://ohtracker.web.illinois.edu/api/get_class_info/?crn=" + this.state.crn
    got(url, { json: true })
      .then(response => {
        console.log(response.body)
        this.setState({
          name: response.body[0].name
        })
      })
      .catch(error => {
        console.log("get course info error")
      });
  }

  render() {
    const identity = JSON.parse(localStorage.getItem("userInfo")).identity;
    return (
      <div>
        <Alert variant="success">
          {this.state.name}
        </Alert>
        <Tab.Container defaultActiveKey="schedule">
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">

                <Nav.Item className="nav-item-success">
                  <Nav.Link eventKey="schedule" >Schedule</Nav.Link>
                </Nav.Item>
                {identity === "instructor" &&
                  <Nav.Item>
                    <Nav.Link eventKey="statistics">Statistics</Nav.Link>
                  </Nav.Item>
                }
                {identity === "instructor" &&
                  <Nav.Item>
                    <Nav.Link eventKey="students">Students</Nav.Link>
                  </Nav.Item>
                }


              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="schedule">
                  <Calender crn={this.state.crn} />
                </Tab.Pane>
                {identity === "instructor" &&
                  <Tab.Pane eventKey="statistics">
                    <Statistics crn={this.state.crn} />
                  </Tab.Pane>
                }

                {identity === "instructor" &&
                  <Tab.Pane eventKey="students">
                    <StudentList crn={this.state.crn} />
                  </Tab.Pane>
                }

              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

      </div>
    )

  }


}

export default CourseInfo
