import React from "react"
import { Form, Row, Col, Button } from 'react-bootstrap';
import TimeField from 'react-simple-timefield';
import Icon from '@material-ui/core/Icon';
import dateFns from "date-fns";
import EditableRow from './EditableRow'

class ModalContent extends React.Component{
    constructor(props){
        super();
        console.log(props)
        this.state = {
            list:[],
            crn:props.crn,
            day: props.day,
            isAdding: false,
            newStartTime:props.day.getTime()/1000,
            newEndTime:props.day.getTime()/1000,
            newLocation: "",
            newNotes: "",
            onUpdate: props.onUpdate
        }
        this.onRemove = this.onRemove.bind(this);
        this.startAdd = this.startAdd.bind(this);
        this.updateList = this.updateList.bind(this);
        this.onStartTimeChange = this.onStartTimeChange.bind(this)
        this.onEndTimeChange = this.onEndTimeChange.bind(this)
        this.onLocationChange = this.onLocationChange.bind(this)
        this.onNotesChange = this.onNotesChange.bind(this)
        this.updateList();
    }

    updateList(){
        var got = require('got');
        var url = 'http://ohtracker.web.illinois.edu/api/get_office_hours/?crn=' + this.state.crn;
        console.log('url:' + url);
        got (url, {json :true})
        .then(response => {
            //var updatedList = response.body.filter(function (item) {
            //    return dateFns.isSameDay( this.state.day, (item.date*1000))
            //})
            var updatedList = response.body
            updatedList = updatedList.filter((item)=>{
                if(item.startTime) return dateFns.isSameDay(this.state.day, 1000*item.startTime)
                return false
            })
            console.log("updatedList:", updatedList)
            this.setState({
                list: updatedList
            })
            this.state.onUpdate()
        })
        .catch(error => {"error"});
    }


    onRemove(id){
        //TODO remove the oh from db
        var got = require('got');
        var url = 'http://ohtracker.web.illinois.edu/api/get_office_hours/?crn=' + this.state.crn;
        console.log('url:' + url);
        got (url, {json :true})
        .then(response => {
            //var updatedList = response.body.filter(function (item) {
            //    return dateFns.isSameDay( this.state.day, (item.date*1000))
            //})
            var updatedList = response.body
            updatedList = updatedList.filter((item)=>{
                if(item.startTime) return dateFns.isSameDay(this.state.day, 1000*item.startTime)
                return false
            })
            console.log("updatedList:", updatedList)
            this.setState({
                list: updatedList
            })
        })
        .catch(error => {"error"});

        const new_list = this.state.list.filter(function (info) {
            return info.ID !== id
         })

         console.log(new_list)

         this.setState({
             list: new_list
         })

         this.state.onRemove(id)
    }

    handleAdd(confirm){
        if(confirm){
            var got = require('got');
            var dateFormat = require('dateformat');
            const date = new Date(dateFormat(1000*this.state.newStartTime, "mmmm dd yyyy"))
            var url = "http://ohtracker.web.illinois.edu/api/office_hour/add/?endTime="+this.state.newEndTime+
                      "&startTime="+this.state.newStartTime+
                      "&location="+this.state.newLocation+
                      "&date="+date.getTime()/1000+
                      "&crn="+this.state.crn+
                      "&notes="+this.state.newNotes;
            url = url.replace(" ", "%20")
            console.log('url:' + url);

            got (url, {json :true})
            .then(response => {
                console.log("updating...")
                this.updateList()
            })
            .catch(error => {"error"});


        }
        this.setState({
            isAdding:false
        })
    }

    startAdd(){
        this.setState({
            isAdding:true
        })
    }

    onStartTimeChange(value){
        console.log("start time changed")
        var dateFormat = require('dateformat');
        const dayInfo = dateFormat(1000*this.state.newStartTime, "mmmm dd yyyy")
        var newTime = new Date(dayInfo+" "+value).getTime()/1000
        console.log(newTime)
        this.setState({
            newStartTime: newTime
        })
    }

    onEndTimeChange(value){
        console.log("end time changed")
        var dateFormat = require('dateformat');
        const dayInfo = dateFormat(1000*this.state.newEndTime, "mmmm dd yyyy")
        var newTime = new Date(dayInfo+" "+value).getTime()/1000
        console.log(newTime)
        this.setState({
            newEndTime: newTime
        })
    }

    onNotesChange(e){
        const value = e.target.value
        console.log(value)
        this.setState({
            newNotes: value
        })
    }

    onLocationChange(e){
        const value = e.target.value
        console.log(value)
        this.setState({
            newLocation: value
        })
    }

    render(){
        var dateFormat = require('dateformat');
        const identity = JSON.parse(localStorage.getItem('userInfo')).identity;

        return(
            <div>
            {this.state.list.map((info, idx) => <EditableRow day={this.state.day} info={info} key={""+info.ID+info.startTime+info.endTime+info.notes+info.location} onUpdate={this.updateList} />)}
            {this.state.list.length === 0 && !this.state.isAdding && <div><p>No office hour for today</p><hr/></div>}

            {this.state.isAdding &&
            <div style={{marginBottom:"15px"}}>
                <Row>
                    <Col sm={4}>
                    From: <TimeField value={dateFormat(1000*this.state.newStartTime, "HH:MM")} onChange={(value)=>{this.onStartTimeChange(value)}}
                                              style={{
                                              border: '0px solid #grey',
                                              fontSize: 20,
                                              width: 100,
                                              height: 30,
                                              color: '#333',
                                              borderRadius: 3
                                            }}/>
                    </Col>
                    <Col sm={4}>
                    To: <TimeField value={dateFormat(1000*this.state.newEndTime, "HH:MM")} onChange={(value)=>{this.onEndTimeChange(value)}}
                                              style={{
                                              border: '0px solid #grey',
                                              fontSize: 20,
                                              width: 100,
                                              height: 30,
                                              color: '#333',
                                              borderRadius: 3
                                          }}/>
                    </Col>
                    <table>
                        <tbody>
                            <tr>
                                <td sm={1}><Button variant="link" onClick={()=>this.handleAdd(true)}>Add</Button></td>
                                <td sm={1}><Button variant="link" onClick={()=>this.handleAdd(false)}>Cancel</Button></td>
                            </tr>
                        </tbody>
                    </table>

                </Row>
                <Row>
                    <Col>Location: <Form.Control as="textarea" rows="3" onChange={this.onLocationChange}/></Col>
                </Row>
                <Row>
                    <Col>Notes: <Form.Control as="textarea" rows="3" onChange={this.onNotesChange}/></Col>
                </Row>
            </div>}
            {!this.state.isAdding && identity === "instructor" &&
                <Row>
                    <Icon color="disabled" style={{position:"absolute",left:"50%", marginBottom:"15px"}}
                          onClick = {this.startAdd}>
                        add_circle
                    </Icon>
                </Row>
            }
            </div>
        )
    }


}

export default ModalContent;
