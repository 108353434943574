import React from "react"
import { Form } from 'react-bootstrap'

class NewCourseModal extends React.Component{
    constructor(props){
        super()
        const departmentList = require('../../data/majors.json')
        departmentList.sort()
        departmentList.push('Other')
        this.state = {
            departmentList:departmentList,
            courseInfo:
            {
                newCRN:"",
                newSection:"",
                newName:"",
                newCode:"",
                newDepartment:departmentList[0]
            }
        }
        this.state.onUpdate = props.onUpdate
        this.handleChange = this.handleChange.bind(this)
        this.state.onUpdate("newCRN", "")
        this.state.onUpdate("newSection", "")
        this.state.onUpdate("newName", "")
        this.state.onUpdate("newCode", "")
        this.state.onUpdate("newDepartment", departmentList[0])

    }

    handleChange(e){
      const value = e.target.value
      const id = e.target.id.toString()
      this.setState(prevState=>{
        return ({
          courseInfo: {...prevState.courseInfo, [id]: value}}
        )
      })

      this.state.onUpdate(id, value)
    }

    render(){
        return(
            <Form>
            <Form.Group controlId="newCRN">
              <Form.Label>CRN</Form.Label>
              <Form.Control type="text" placeholder="CRN of course" onChange={this.handleChange} />
              </Form.Group>

              <Form.Group controlId="newSection">
                <Form.Label>Section</Form.Label>
                <Form.Control type="text"  placeholder="Section of course" onChange={this.handleChange}/>
                </Form.Group>

                <Form.Group controlId="newName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text"  placeholder="Name of course" onChange={this.handleChange}/>
                  </Form.Group>



              <Form.Group controlId="newDepartment" >
              <Form.Label>Department</Form.Label>
              <Form.Control as="select" onChange={this.handleChange}>
                {this.state.departmentList.map((department, idx) =>
                  <option key={idx}>{department}</option>
                )}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="newCode">
              <Form.Label>Code</Form.Label>
              <Form.Control type="text"  placeholder="Code of course" onChange={this.handleChange}/>
              </Form.Group>


            </Form>
        )
    }

}

export default NewCourseModal
