import React from "react";
import dateFns from "date-fns";
import "../Calender.css";
import { Button, Modal, Alert } from 'react-bootstrap';
import ModalContent from './ModalContent'
import { notify } from 'react-notify-toast'

export default class Calendar extends React.Component {
    constructor(props) {
        super()
        this.state = {
            currentMonth: new Date(),
            selectedDate: new Date(),
            oh_list: [],
            crn: props.crn,
            showModal: false
        };
        this.updateOHList = this.updateOHList.bind(this)
        this.onDateClick = this.onDateClick.bind(this)
        this.nextMonth = this.nextMonth.bind(this)
        this.prevMonth = this.prevMonth.bind(this)
        this.handleClose = this.handleClose.bind(this)

        this.toast = notify.createShowQueue()
        this.sendToast = this.sendToast.bind(this)

        this.updateOHList()
    }

    sendToast(message) {
        let myStyle = {
            background: 'transparent',
        }

        this.toast(
            <Alert variant='danger'>
                {message}
            </Alert>
            , 'custom', 2000, myStyle)
    }

    updateOHList() {
        const got = require('got');
        var url = 'http://ohtracker.web.illinois.edu/api/get_office_hours/?crn=' + this.state.crn

        got(url, { json: true })
            .then(response => {
                var data = response.body
                this.setState({
                    oh_list: data
                })
            })
            .catch(error => {
                console.log('Catched error:', error);
            });
    }

    handleClose() {
        this.setState({
            showModal: false
        })
    }

    renderHeader() {
        const dateFormat = "MMMM YYYY";

        return (
            <div className="header row flex-middle">
                <div className="col col-start">
                    <div className="icon" onClick={this.prevMonth}>
                        chevron_left
              </div>
                </div>
                <div className="col col-center">
                    <span>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
                </div>
                <div className="col col-end" onClick={this.nextMonth}>
                    <div className="icon">chevron_right</div>
                </div>
            </div>
        );
    }

    renderDays() {
        const dateFormat = "dddd";
        const days = [];

        let startDate = dateFns.startOfWeek(this.state.currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
                </div>
            );
        }

        return <div className="days row">{days}</div>;
    }



    renderCells() {
        const { currentMonth, selectedDate } = this.state;
        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart);
        const endDate = dateFns.endOfWeek(monthEnd);


        const dateFormat = "D";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";

        let customFilter = (item) => {
            return dateFns.isSameDay(day, (item.date * 1000))
        }

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = dateFns.format(day, dateFormat);
                const cloneDay = day;

                const oh_today = this.state.oh_list.filter(customFilter)

                days.push(
                    <div
                        className={`col cell ${
                            !dateFns.isSameMonth(day, monthStart)
                                ? "disabled"
                                : dateFns.isSameDay(day, selectedDate) ? "selected" : ""
                            }`}
                        key={day}
                        onClick={() => this.onDateClick(dateFns.parse(cloneDay), oh_today.length > 0)}
                    >
                        <span className="number">{formattedDate}</span>
                        <span className="bg">{formattedDate}</span>
                        <div style={{
                            position: "absolute",
                            bottom: 0,
                            right: ".5em"
                        }}>
                            {
                                oh_today.length > 0 &&
                                <div>
                                    <Indicator day={day} monthStart={this.state.currentMonth} />
                                </div>
                            }
                        </div>
                    </div>
                );
                day = dateFns.addDays(day, 1);
            }
            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    }

    onDateClick = (day, hasOfficeHours) => {
        const identity = JSON.parse(localStorage.getItem('userInfo')).identity
        if (identity === 'student' && !hasOfficeHours) {

            this.sendToast('There are no office hours that day!')

            this.setState({
                selectedDate: day,
                showModal: false
            });
            return    
        }

        this.setState({
            selectedDate: day,
            showModal: true
        });

    };

    nextMonth = () => {
        this.setState({
            currentMonth: dateFns.addMonths(this.state.currentMonth, 1)
        });
    };

    prevMonth = () => {
        this.setState({
            currentMonth: dateFns.subMonths(this.state.currentMonth, 1)
        });
    };

    render() {
        var dateFormat = require('dateformat');
        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showModal}
                    onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{dateFormat(this.state.selectedDate, "dddd, mmmm dS, yyyy")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: 'calc(80vh - 210px)', overflowY: 'auto' }}>
                        <ModalContent day={this.state.selectedDate} crn={this.state.crn} onUpdate={this.updateOHList} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={this.handleClose}>
                            Close
                     </Button>
                    </Modal.Footer>
                </Modal>

                <div className="calendar">
                    {this.renderHeader()}
                    {this.renderDays()}
                    {this.renderCells()}
                </div>
            </div>
        );
    }
}

class Indicator extends React.Component {
    constructor(props) {
        super();
        this.state = { props: props }

    }
    render() {
        return (
            <div style={{
                display: "inline-block",
                backgroundColor: "#d1ecf1",
                height: 15,
                width: 15,
                margin: 1.5,
                borderRadius: "50%",
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: '#bee5eb',
            }}></div>)
    }
};
