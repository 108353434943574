import React, { Component } from "react";
import CanvasJSReact from '../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class Test extends Component {
    constructor() {
        super();
        this.state = {
            data:[]
        }
        this.getData = this.getData.bind(this)
        this.getData()
    }

    getData(){
        var got = require('got');
        var url = "http://ohtracker.web.illinois.edu/api/get_attendance_count_and_grades/?crn=31352"
        console.log(url)

        got (url, {json :true})
        .then(response => {
            var data = response.body
            data = data.map((item) => {return {
                    type: "scatter",
                    name: item.netId,
                    markerType: "circle",
                    toolTipContent: "<span style=\"color:#4F81BC \">{name}</span><br>attendence: {x}<br>grade: {y}",
                    dataPoints: [
                        { x: item.attendanceCount, y: item.grade, markerSize: 15, color: "rgba(40, 167, 69, 0.3)"}]
                }
            })

            this.setState({ data: data})
        })
        .catch(error => {"error"});

    }
    render() {
        const options = {
            type: "scatter",
            animationEnabled: true,
            showInLegend: true,
			axisX: {
				title:"Attendance Count",
                labelWrap: false,
                titleFontColor:"#8c8c8c",
                labelFontFamily:"sans-serif",
                tickLength:2,
				crosshair: {
					enabled: true,
					snapToDataPoint: true
				}
			},
			axisY:{
				title: "Grade",
                titleFontColor:"#8c8c8c",
                interval: 20,
                //interlacedColor: "#f7fff7",
                gridColor: "lightgrey" ,
				includeZero: true,
				crosshair: {
					enabled: true,
					snapToDataPoint: true
				}
			},

			data: this.state.data
        }

        return (
        <div>
            <CanvasJSChart options = {options}
                onRef={ref => this.chart = ref}
            />
        </div>
        );
    }
}

export default Test
