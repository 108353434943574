import React from 'react'
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'

class CourseItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = { item: props.item, onRemove: props.onRemove }
    }

    render() {
        var instructors = this.state.item.Instructors.join(', ')

        return (
            <Link to={'/course/' + this.state.item.CRN}>
                <Card className="course-item" style={{ color: "black" }}>
                    <Card.Header style={{ width: "100%" }}>{this.state.item.Code} ({this.state.item.Section})</Card.Header>
                    <Card.Body style={{ width: "100%" }}>
                        <Card.Title>{this.state.item.CourseName}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                            <b>CRN: {this.state.item.CRN}</b>
                        </Card.Subtitle>
                        <Card.Text className="text-muted">
                            {instructors}
                        </Card.Text>
                        <Link to="#" onClick={() => {
                            this.state.onRemove(this.state.item.CRN)
                        }} >Remove</Link>
                    </Card.Body>
                </Card>
            </Link>
        )
    }
}

export default CourseItem
