import React from 'react';
import { Form, Button } from 'react-bootstrap'
import swal from 'sweetalert';
import { withRouter } from 'react-router';
import ValidationMessage from './ValidationMessage';

const INSTRUCTOR = "instructor";
const STUDENT = "student"

const VALID_PATTERN = /^[0-9a-zA-Z]+$/

function lettersAndNumbers(str) {

    if (!str) {
        return false
    }

    return str.match(VALID_PATTERN)
}

class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            userInfo: {
                username: null,
                password: null,
                identity: STUDENT
            },
            validation: {
                validated: false,
                netid: {
                    invalid: false,
                    message: ''
                },
            },

            handleLogIn:props.handleLogIn
        }

        this.handleIdentityChange = this.handleIdentityChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleTextChange = this.handleTextChange.bind(this)
        this.authentication = this.authentication.bind(this)

        this.initialValidation = this.initialValidation.bind(this)
        this.resetValidation = this.resetValidation.bind(this)
        this.validation_netIdNotRegistered = this.validation_netIdNotRegistered.bind(this)
    }

    authentication(NetID, identity, password) {
        // BACKEND :: AUTHENTICATION WITH DATABASE

        return true
    }

    handleIdentityChange(props) {
        this.setState(prevState => {
            return ({
                userInfo: { ...prevState.userInfo, identity: props }
            }
            )
        })
    }

    initialValidation(NetId) {
        if (lettersAndNumbers(NetId)) {
            return true
        }

        var tempValidation = this.state.validation

        if (!lettersAndNumbers(NetId)) {
            tempValidation.netid.invalid = true
            tempValidation.netid.message = 'Please enter a valid NetID!'
        }

        tempValidation.validated = true

        this.setState({ validation: tempValidation })

        return false
    }

    validation_netIdNotRegistered() {
        var tempValidation = this.state.validation

        tempValidation.netid.invalid = true
        tempValidation.netid.message = 'We could not validate that NetID!'

        tempValidation.validated = true

        this.setState({ validation: tempValidation })

    }

    resetValidation() {
        var tempValidation = this.state.validation

        tempValidation.netid.invalid = false
        tempValidation.validated = false

        this.setState({ validation: tempValidation })

    }

    handleSubmit() {

        this.resetValidation()

        if (!this.initialValidation(this.state.userInfo.username)) {
            return
        }

        var got = require("got")
        var url = ""
        if (this.state.userInfo.identity === "student") {
            url = "http://ohtracker.web.illinois.edu/api/get_student_info/?netid=" + this.state.userInfo.username
        }

        else if (this.state.userInfo.identity === "instructor") {
            url = "http://ohtracker.web.illinois.edu/api/get_professor_info/?netid=" + this.state.userInfo.username
        }
        console.log("url:", url)
        got(url, { json: true }).then(response => {
            if (response.body.length > 0) {
                console.log(response.body)
                swal("Athenticated!", "Proceeding to homepage!", "success")
                localStorage.setItem('userInfo', JSON.stringify(this.state.userInfo))
                console.log(JSON.parse(localStorage.getItem('userInfo')))
                this.state.handleLogIn()
                this.props.history.push('/home')
            }
            else {
                this.validation_netIdNotRegistered()
                // swal("Not Authenticated", "Please correct your information", "error")
            }
        }).catch(error => {
            console.log(error)
            swal("Error Occurred!", "Something went wrong", "error")
        });
    }

    handleTextChange(e) {

        this.resetValidation()

        const value = e.target.value
        if (e.target.name === "password")
            this.setState(prevState => {
                return ({
                    userInfo: { ...prevState.userInfo, password: value }
                }
                )
            })
        else if (e.target.name === "username")
            this.setState(prevState => {
                return ({
                    userInfo: { ...prevState.userInfo, username: value }
                }
                )
            })
    }

    render(props) {

        let validated = this.state.validation.validated

        let netIdInvalid = validated && this.state.validation.netid.invalid
        let netIdMessage = this.state.validation.netid.message

        return (
            <div>
                <div className='login-form'>
                    <Form>

                        <Form.Group controlId="UserName">

                            <Form.Label>NetID</Form.Label>
                            <ValidationMessage
                                enabled={netIdInvalid}
                                message={netIdMessage}
                            />
                            <Form.Control
                                name="username"
                                type="text"
                                placeholder="Enter username"
                                onChange={this.handleTextChange}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        this.handleSubmit()
                                    }
                                }}
                            />
                            <Form.Text className="text-muted">
                                Type in your NetID.
                            </Form.Text>

                        </Form.Group>

                        <Form.Group controlId="Password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control disabled name="password" type="password" placeholder="Password Usage Disabled" onChange={this.handleTextChange} />
                        </Form.Group>


                        <Form.Label>Identity</Form.Label>
                        <Form.Group controlId="Checkbox">

                            <Form.Check
                                inline
                                id="instructorRadio"
                                label="Instructor"
                                type="radio"
                                checked={this.state.userInfo.identity === INSTRUCTOR}
                                onChange={() => { this.handleIdentityChange(INSTRUCTOR) }}
                            />

                            <Form.Check
                                inline
                                id="studentRadio"
                                label="Student"
                                type="radio"
                                checked={this.state.userInfo.identity === STUDENT}
                                onChange={() => { this.handleIdentityChange(STUDENT) }}
                            />

                        </Form.Group>

                        <Button variant="success" onClick={this.handleSubmit}>
                            Submit
                        </Button>
                    </Form>
                </div>
            </div>
        )
    }
}

export default withRouter(Login)
