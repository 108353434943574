import React from "react"
import {Row, Col} from "react-bootstrap"
import StudentItem from "./StudentItem"

class StudentList extends React.Component{
    constructor(props){
        super()
        this.state = {
            crn : props.crn,
            studentlist : []
        }
        this.getStudentsList = this.getStudentsList.bind(this)
        this.getStudentsList()
    }

    getStudentsList(){
        const url = "http://ohtracker.web.illinois.edu/api/get_attendance_count_and_grades/?crn="+this.state.crn
        console.log(url)
        var got = require("got")
        got (url, {json :true})
        .then(response => {
            console.log(response.body)
            this.setState({
                studentlist:response.body
            })

        })
        .catch(error => {
            console.log("error")
        });
    }

    render(){
        return(<div>
                    <Row>
                        <Col md="3">Name:</Col>
                        <Col md="3">Attendance:</Col>
                        <Col md="3">Grade:</Col>
                    </Row>
                    <hr/>
                    <div style={{height: 'calc(80vh - 30px)', overflowY: 'auto'}}>
                    {this.state.studentlist.map((item,idx)=><StudentItem key = {item.netId}
                                                                         netId={item.netId}
                                                                         attendanceCount={item.attendanceCount}
                                                                         grade={item.grade}
                                                                         crn={this.state.crn}/>)}
                    </div>
                </div>)
    }
}

export default StudentList;
