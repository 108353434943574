import React from "react"
import {Row, Col, Button, Form} from "react-bootstrap"

class StudentItem extends React.Component{
    constructor(props){
        super()
        this.state={
            crn: props.crn,
            isGrading: false,
            netId: props.netId,
            name:"",
            grade: props.grade,
            attendanceCount: props.attendanceCount,
            newGrade: props.grade>0? props.grade:0
        }
        this.startGrading = this.startGrading.bind(this)
        this.getStudentName = this.getStudentName.bind(this)
        this.cancelGrading = this.cancelGrading.bind(this)
        this.onGradeChange = this.onGradeChange.bind(this)
        this.confirmGrading = this.confirmGrading.bind(this)
        this.getStudentName = this.getStudentName.bind(this)

        this.getStudentName()
    }

    getStudentName(){
        const url="http://ohtracker.web.illinois.edu/api/get_student_info/?netid="+this.state.netId
        var got = require("got")
        got(url, { json: true })
        .then(response => {
            var data = response.body
            this.setState({
                name:data[0].name
            })
        })
        .catch(error => {
            console.log('Catched error:', error);
        });
    }

    startGrading(){
        this.setState({
            isGrading: true
        })
    }

    onGradeChange(e){
          const value = e.target.value
          this.setState(prevState=>{
            return ({newGrade: value})
        })
    }

    confirmGrading(){
        console.log(this.state)
        const url = "http://ohtracker.web.illinois.edu/api/update_grade/?crn="+this.state.crn+
                    "&netid="+this.state.netId+
                    "&grade="+this.state.newGrade
        console.log(url)
        var got = require("got")
        got(url, { json: true })
        .then(response => {
            this.setState({
                grade: this.state.newGrade
            })
        })
        .catch(error => {
            console.log('Catched error:', error);
        });

        this.setState({
            isGrading: false
        })
    }

    cancelGrading(){
        this.setState({
            newGrade: this.state.grade,
            isGrading: false
        })
    }


    render(){
        return(
            <div style={{marginTop:"25px"}}>
                <Row>
                    <Col md="3">{this.state.name}</Col>
                    <Col md="3">{this.state.attendanceCount}</Col>
                    {!this.state.isGrading&&<Col md="3">{this.state.grade<0?"not graded":this.state.grade}</Col>}
                    {this.state.isGrading&&
                      <Col md="3">
                          <Form.Group controlId="newGrade" onChange={this.onGradeChange}>
                          <Form.Control type="text"  value={this.state.newGrade} onChange={this.handleChange}/>
                          </Form.Group>
                      </Col>
                    }
                    {!this.state.isGrading&&<Col md="1"><Button onClick={this.startGrading} variant="link">{this.state.grade>=0?"Regrade":"Grade"}</Button></Col>}
                    {this.state.isGrading&&<Col md="1"><Button  onClick={this.confirmGrading} variant="link">Confirm</Button></Col>}
                    {this.state.isGrading&&<Col md="1"><Button onClick={this.cancelGrading} variant="link">Cancel</Button></Col>}
                </Row>
                <hr/>
            </div>
        )
    }
}

export default StudentItem
