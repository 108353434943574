import React from "react";

class ValidationMessage extends React.Component {

    render() {

        let response = ''

        if (this.props.enabled) {
            response = this.props.message
        }

        return (
            <div style={{color: 'red'}}>
                {response}
            </div>
        )
    }
}

export default ValidationMessage