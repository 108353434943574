import React from 'react'
import { Card } from 'react-bootstrap';

class UnaddedCourseItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = { item: props.item, onClick: props.onClick }
    }

    render() {
        var instructors = this.state.item.Instructors.join(', ')

        return (
            <Card className="course-item unadded-course-item" style={{ color: "black" }}
                onClick={() => {
                    console.log('registering for ' + this.state.item.CRN)
                    this.state.onClick(this.state.item)
                }}>

                <Card.Header style={{ width: "100%" }}>{this.state.item.Code} ({this.state.item.Section})</Card.Header>
                <Card.Body style={{ width: "100%" }}>
                    <Card.Title>{this.state.item.CourseName}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                        <b>CRN: {this.state.item.CRN}</b>
                    </Card.Subtitle>
                    <Card.Text className="text-muted">
                        {instructors}
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }
}

export default UnaddedCourseItem
