import React from 'react';
import EditableLabel from 'react-inline-editing';
import { Row, Col, Form, Button } from 'react-bootstrap'

class Profile extends React.Component {
    constructor(props) {
        super(props);

        const defaultFullInfo = {
            username: "",
            name: "name",
            yearEnroll: 0,
            department: ""
        }

        const departmentList = JSON.parse(JSON.stringify(require('../data/majors.json')))
        console.log('unchanged departments: ', departmentList)
        departmentList.sort()
        departmentList.push('Other')

        this.state = {
            fullInfo: defaultFullInfo,
            isEditable: false,
            departmentList: departmentList
        }

        this._handleFocus = this._handleFocus.bind(this);
        this._handleFocusOut = this._handleFocusOut.bind(this);
        this.getUserInfoFromDB = this.getUserInfoFromDB.bind(this)
        this.handleSelectionChange = this.handleSelectionChange.bind(this)

    }

    componentWillMount() {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"))
        const NetID = userInfo.username
        const identity = userInfo.identity
        this.getUserInfoFromDB(NetID, identity)
    }

    getUserInfoFromDB(NetID, identity) {
        var got = require('got');

        var url = ''

        if (identity === 'student') {
            url = 'http://ohtracker.web.illinois.edu/api/get_student_info/?netid=' + NetID;
        } else {
            url = 'http://ohtracker.web.illinois.edu/api/get_professor_info/?netid=' + NetID;
        }

        console.log('url:' + url);
        got(url, { json: true })
            .then(response => {
                this.setState({
                    fullInfo: response.body[0],
                    isEditable: true
                })
            })
            .catch(error => {
                console.log('Caught error:', error);
                this.setState({
                    fullInfo: {
                        username: "ERROR: Please reload page",
                        name: "ERROR: Please reload page",
                        yearEnroll: 0,
                        department: "ERROR: Please Reload Page"
                    }
                })
            });
    }
    _handleFocus(text) {
        console.log('Focused');
    }

    _handleFocusOut(text) {
        this.setState(prevState => {
            return ({
                fullInfo: { ...prevState.fullInfo, name: text }
            }
            )
        })
    }

    handleSelectionChange(e) {
        const value = e.target.value
        if (e.target.id === "yearEnroll") {
            this.setState(prevState => {
                return ({
                    fullInfo: { ...prevState.fullInfo, yearEnroll: value }
                }
                )
            })
        }

        if (e.target.id === "department") {
            this.setState(prevState => {
                return ({
                    fullInfo: { ...prevState.fullInfo, department: value }
                }
                )
            })
        }
    }


    render() {
        const thisYear = new Date().getFullYear()
        const yearSpan = 10
        const yearList = Array(yearSpan).fill().map((_, idx) => thisYear - idx)
        var userInfo = JSON.parse(localStorage.getItem("userInfo"))
        const identity = userInfo.identity

        return (
            <div>
                <div className="profile-style">
                    <Row>
                        <Col sm xs className='myProfileUnEditableClass'  >
                            <p style={{ "width": "100%", "textAlign": "right" }} >NetID:</p>
                        </Col>
                        <Col sm xs className='myProfileUnEditableClass' >
                            <p>{this.state.fullInfo.username}</p>
                        </Col>

                        <Col sm xs ></Col>
                    </Row>

                    <Row>
                        <Col sm xs className='myProfileUnEditableClass'  >
                            <p style={{ "width": "100%", "textAlign": "right" }} >Name:</p>
                        </Col>
                        <Col sm xs className='myProfileEditableClass' >
                            {this.state.isEditable &&
                                <EditableLabel
                                    text={this.state.fullInfo.name}
                                    //labelClassName='myProfileClass'
                                    inputClassName='myInputClass'
                                    FontWeight="light"
                                    inputFontWeight="light"
                                    onFocus={this._handleFocus}
                                    onFocusOut={this._handleFocusOut}
                                />
                            }
                        </Col>
                        <Col sm xs ></Col>
                    </Row>
                    {identity === "student" &&
                        <Row>
                            <Col sm xs className='myProfileUnEditableClass'  >
                                <p style={{ "width": "100%", "textAlign": "right" }} >Enrollment:</p>
                            </Col>
                            <Col sm xs className='myProfileEditableClass' >
                                <Form.Group controlId="yearEnroll">
                                    <Form.Control as="select" onChange={this.handleSelectionChange} value={this.state.fullInfo.yearEnroll}>
                                        {yearList.map((year, idx) =>
                                            <option key={idx}>{year}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm xs ></Col>
                        </Row>
                    }

                    <Row>
                        <Col sm xs className='myProfileUnEditableClass'  >
                            <p style={{ "width": "100%", "textAlign": "right" }} >Department:</p>
                        </Col>
                        <Col sm xs className='myProfileEditableClass' >
                            <Form.Group controlId="department">
                                <Form.Control as="select" value={this.state.fullInfo.department} onChange={this.handleSelectionChange}>
                                    {this.state.departmentList.map((department, idx) =>
                                        <option key={idx}>{department}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm xs ></Col>
                    </Row>
                    <Row><br /></Row>
                    <Row>
                        <Col></Col>
                        <Col>
                            <Button variant="success" onClick={this.handleSubmit} className="profile-button">Change</Button>
                        </Col>
                        <Col></Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default Profile
