import React from "react";
import "../Calender.css";
import ScatterPlot from './ScatterPlot'
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';

class Statistics extends React.Component{
    constructor(props){
        super()
        this.state = {
            crn:props.crn
        }
    }
    render(){
        return(
        <div  style={{maxHeight: 'calc(80vh - 30px)', overflowY: 'auto'}}>
            <Accordion atomic={true}>
                <AccordionItem title="Grade vs Attendance Count">
                    <div style={{backgroundColor: "white", textAlign:"center", padding:"15px"}}>
                      <ScatterPlot crn={this.state.crn} />
                    </div>
                 </AccordionItem>

                 <AccordionItem title="Other statistics">
                    <div>Other statistics</div>
                  </AccordionItem>

            </Accordion>
        </div>
    )
    }
}

export default Statistics
