import React from "react"
import { Form, Row, Col, Button } from 'react-bootstrap';
import TimeField from 'react-simple-timefield';

class EditableRow extends React.Component{
    constructor(props){
        super()
        const personInfo = JSON.parse(localStorage.getItem('userInfo'));

        this.state=
        {
            isEditable:false,
            info:props.info,
            attend: false,
            onRemove: props.onRemove,
            day: props.day,
            identity: personInfo.identity,
            NetID:personInfo.username,
            isSingningUp: false,

            newStartTime:props.info.startTime,
            newEndTime: props.info.endTime,
            newLocation: props.info.location,
            newNotes: props.info.notes,
            onUpdate:props.onUpdate,
            Topics:"",
            attendStart:props.info.startTime,
            attendEnd:props.info.endTime
        }

        console.log(this.state)
        this.handleRemove = this.handleRemove.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.handleAttend = this.handleAttend.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.onStartTimeChange = this.onStartTimeChange.bind(this)
        this.onEndTimeChange = this.onEndTimeChange.bind(this)
        this.onLocationChange = this.onLocationChange.bind(this)
        this.onNotesChange = this.onNotesChange.bind(this)
        this.checkAttendence = this.checkAttendence.bind(this)
        this.onTopicsChange = this.onTopicsChange.bind(this)
        this.onAttendStart = this.onAttendStart.bind(this)
        this.onAttendEnd = this.onAttendEnd.bind(this)
        this.stopAttend = this.stopAttend.bind(this)
        var dateFormat = require('dateformat');
        console.log(dateFormat(props.day, "yyyy-mmmm-dd hh:MM TT"))
        this.checkAttendence()
    }

    onAttendStart(value){
        console.log("attend start changes")
        var dateFormat = require('dateformat');
        const dayInfo = dateFormat(1000*this.state.info.startTime, "mmmm dd yyyy")
        var newTime = new Date(dayInfo+" "+value).getTime()/1000
        this.setState({
            attendStart: newTime
        })
        console.log(new Date(newTime*1000))
    }

    onAttendEnd(value){
        console.log("attend end changes")
        var dateFormat = require('dateformat');
        const dayInfo = dateFormat(1000*this.state.info.startTime, "mmmm dd yyyy")
        var newTime = new Date(dayInfo+" "+value).getTime()/1000
        this.setState({
            attendEnd: newTime
        })
        console.log(new Date(newTime*1000))
    }

    checkAttendence(){
        const NetID = this.state.NetID;
        const crn = this.state.info.Class_CRN;
        const id = this.state.info.ID;
        var got = require('got');
        var url = "http://ohtracker.web.illinois.edu/api/office_hours/get_attendance/?crn="+crn+
                  "&id="+id;
        console.log(url)

        got (url, {json :true})
        .then(response => {
            const attendence = response.body.filter(function (item) {
                return (item.netId === NetID)
            })
            console.log(attendence)
            if (attendence.length>0){
                this.setState({
                    attend: true,
                    attendStart: attendence[0].timeArrived,
                    attendEnd: attendence[0].timeLeft,
                    Topics: attendence[0].questionTopic
                })

                console.log(this.state)
            }
        })
        .catch(error => {"error"});

    }

    handleEdit(){
        this.setState({isEditable: true})
    }

    handleChanged(confirm){
        if(confirm){

        var got = require('got');
        var dateFormat = require('dateformat');
        const date = new Date(dateFormat(1000*this.state.newStartTime, "mmmm dd yyyy"))
        var url = "http://ohtracker.web.illinois.edu/api/office_hour/edit/?id="+this.state.info.ID+
                  "&endTime="+this.state.newEndTime+
                  "&startTime="+this.state.newStartTime+
                  "&location="+this.state.newLocation+
                  "&date="+date.getTime()/1000+
                  "&crn="+this.state.info.Class_CRN+
                  "&notes="+this.state.newNotes;
        url=url.replace(" ", "%20")
        console.log('url:' + url);

        got (url, {json :true})
        .then(response => {
            console.log("updating...")
            this.state.onUpdate()
        })
        .catch(error => {"error"});
        }

        else{
            if(!confirm){
                this.setState({
                    newStartTime:this.state.info.startTime,
                    newEndTime: this.state.info.endTime,
                    newLocation: this.state.info.location,
                    newNotes: this.state.info.notes,
                })
            }
        }

        this.setState({
            isEditable: false
        })

        console.log(confirm)
    }

    handleRemove(){
        console.log("remove:", this.state.info.ID)
        var got = require('got');
        var url = "http://ohtracker.web.illinois.edu/api/office_hour/remove/?id="+this.state.info.ID+
                  "&crn="+this.state.info.Class_CRN
        url=url.replace(" ", "%20")
        console.log('url:' + url);

        got (url, {json :true})
        .then(response => {
            console.log("updating...")
            this.state.onUpdate()
        })
        .catch(error => {"error"});

    }

    handleAttend(){
        if (!this.state.isSingningUp){
            this.setState({
                isSingningUp: true
            })
            return
        }

        var got = require('got');
        var NetID = JSON.parse(localStorage.getItem('userInfo')).username;
        //console.log("Attending...")
        //console.log(new Date(this.state.attendStart*1000))
        //console.log(new Date(this.state.attendEnd*1000))
        var url = "http://ohtracker.web.illinois.edu/api/office_hours/sign_in/?id="+this.state.info.ID+
                  "&crn="+this.state.info.Class_CRN+
                  "&netid="+ NetID+
                  "&timeArrived="+ this.state.attendStart+
                  "&timeLeft="+this.state.attendEnd+
                  "&questionTopic="+this.state.Topics;
        url = url.replace(" ", "%20")
        console.log(url)

        got (url, {json :true})
        .then(response => {
            console.log("Attend result:", response)
            this.setState({
                attend: true
            })
            this.state.onUpdate();
        })
        .catch(error => {"error"});
    }

    stopAttend(){
        this.setState({
            isSingningUp: false
        })
        return
    }

    handleCancel(){

        var got = require('got');
        var NetID = JSON.parse(localStorage.getItem('userInfo')).username;
        var url = "http://ohtracker.web.illinois.edu/api/office_hours/cancel_attendance/?id="+this.state.info.ID+
                  "&crn="+this.state.info.Class_CRN+
                  "&netid="+ NetID;
        url = url.replace(" ", "%20")
        console.log(url)

        got (url, {json :true})
        .then(response => {
            console.log("Cancel result:",response.body)
            this.setState({
                attend: false,
            })
            this.state.onUpdate();
        })
        .catch(error => {"error"});

    }

    onStartTimeChange(value){
        console.log("start time changed")
        var dateFormat = require('dateformat');
        const dayInfo = dateFormat(1000*this.state.newStartTime, "mmmm dd yyyy")
        var newTime = new Date(dayInfo+" "+value).getTime()/1000
        this.setState({
            newStartTime: newTime
        })
    }

    onEndTimeChange(value){
        console.log("end time changed")
        var dateFormat = require('dateformat');
        const dayInfo = dateFormat(1000*this.state.newEndTime, "mmmm dd yyyy")
        var newTime = new Date(dayInfo+" "+value).getTime()/1000
        this.setState({
            newEndTime: newTime
        })
        console.log(newTime)
    }

    onNotesChange(e){
        const value = e.target.value
        this.setState({
            newNotes: value
        })
    }

    onLocationChange(e){
        const value = e.target.value
        this.setState({
            newLocation: value
        })
    }

    onTopicsChange(e){
        console.log("topics changed")
        const value = e.target.value
        this.setState({
            Topics: value
        })
    }

    render(){
        var dateFormat = require('dateformat');
        var start_time = new Date(this.state.info.startTime*1000);
        var end_time = new Date(this.state.info.endTime*1000);
        var identity = JSON.parse(localStorage.getItem('userInfo')).identity;

        return(
            <div>
                        <Row>
                            {!this.state.isEditable && <Col sm={4}>From: {dateFormat(start_time, "hh:MM TT")} </Col>}
                            {this.state.isEditable &&
                                <Col sm={4}>
                                From: <TimeField value={dateFormat(1000*this.state.newStartTime, "HH:MM")} onChange={(value)=>{this.onStartTimeChange(value)}}
                                                          style={{
                                                          border: '0px solid #grey',
                                                          fontSize: 20,
                                                          width: 100,
                                                          height: 30,
                                                          color: '#333',
                                                          borderRadius: 3
                                                        }}/>
                                </Col>}
                            {!this.state.isEditable && <Col sm={4}>To: {dateFormat(end_time, "hh:MM TT")} </Col>}
                            {this.state.isEditable && <Col sm={4}>
                            To: <TimeField value={dateFormat(1000*this.state.newEndTime, "HH:MM")} onChange={(value)=>{this.onEndTimeChange(value)}}
                                                      style={{
                                                      border: '0px solid #grey',
                                                      fontSize: 20,
                                                      width: 100,
                                                      height: 30,
                                                      color: '#333',
                                                      borderRadius: 3
                                                    }}/> </Col>}
                            {(identity === "instructor" && !this.state.isEditable) && <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td sm={1} ><Button variant="link" onClick={this.handleEdit}>Edit</Button></td>
                                            <td sm={1} ><Button variant="link" onClick={this.handleRemove}>Remove</Button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            }
                            {(identity === "instructor" && this.state.isEditable )&& <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td sm={1}><Button variant="link" onClick={()=>{this.handleChanged(true)}}>Confirm</Button></td>
                                            <td sm={1}><Button variant="link" onClick={()=>{this.handleChanged(false)}}>Cancel</Button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            }
                            {identity === "student" && !this.state.attend && <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td sm={2}><Button variant="link" onClick={this.handleAttend}>Attend</Button></td>
                                            { this.state.isSingningUp && <td sm={2}><Button variant="link" onClick={this.stopAttend}>Cancel</Button></td>}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            }
                            {identity === "student" && this.state.attend && <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td sm={2}><Button variant="link" onClick ={this.handleCancel}>Cancel</Button></td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                            }
                        </Row>
                {
                    !this.state.isEditable &&
                    <Row>
                        <Col>Location: {this.state.info.location}</Col>
                    </Row>
                }
                {
                    this.state.isEditable &&
                    <Row>
                        <Col>Location: <Form.Control as="textarea" rows="3" value={this.state.newLocation?this.state.newLocation:""} onChange={this.onLocationChange}/></Col>
                    </Row>
                }
                {
                    !this.state.isEditable &&
                    <Row>
                        <Col>Notes: {this.state.info.notes}</Col>
                    </Row>
                }
                {
                    this.state.isEditable &&
                    <Row>
                        <Col>Notes: <Form.Control as="textarea" rows="3" value={this.state.newNotes?this.state.newNotes:""} onChange={this.onNotesChange}/></Col>
                    </Row>
                }

                {
                    identity === "student" && !this.state.attend && this.state.isSingningUp &&
                    <div>
                        <Row style={{marginTop:"20px"}}>
                            <Col sm={4}>
                            Arrive: <TimeField value={dateFormat(1000*this.state.attendStart, "HH:MM")} onChange={(value)=>{this.onAttendStart(value)}}
                                                      style={{
                                                      border: '0px solid #grey',
                                                      fontSize: 20,
                                                      width: 100,
                                                      height: 30,
                                                      color: '#333',
                                                      borderRadius: 3
                                                    }}/>
                            </Col>
                            <Col sm={4}>
                            Leave: <TimeField value={dateFormat(1000*this.state.attendEnd, "HH:MM")} onChange={(value)=>{this.onAttendEnd(value)}}
                                                      style={{
                                                      border: '0px solid #grey',
                                                      fontSize: 20,
                                                      width: 100,
                                                      height: 30,
                                                      color: '#333',
                                                      borderRadius: 3
                                                    }}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Topics:
                                    <Form.Control  as="textarea" rows="1"
                                                  value={this.state.Topics} onChange={this.onTopicsChange}/>
                            </Col>
                        </Row>
                    </div>
                }

                {
                    identity === "student" && this.state.attend &&
                    <div>
                        <Row style={{marginTop:"20px"}}>
                            <Col sm={4}>
                            Arrive: {dateFormat(1000*this.state.attendStart,"hh:MM TT")}
                            </Col>
                            <Col sm={4}>
                            Leave: {dateFormat(1000*this.state.attendEnd, "hh:MM TT")}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Topics: {this.state.Topics}

                            </Col>
                        </Row>
                    </div>
                }

                <hr width="100%" size="8" align="center"/>
            </div>
        )
    }
}

export default EditableRow
