import React, { Component } from "react";
import CanvasJSReact from '../assets/canvasjs.react';
import Icon from '@material-ui/core/Icon';


var CanvasJSChart = CanvasJSReact.CanvasJSChart;


class ScatterPlot extends Component {
    constructor(props) {
        super();
        this.state = {
            data:[],
            crn : props.crn,
            isLoading: true
        }
        this.getData = this.getData.bind(this)
        this.getData()
    }

    getData(){
        var got = require('got');
        var url = "http://ohtracker.web.illinois.edu/api/get_attendance_count_and_grades/?crn="+this.state.crn
        console.log(url)

        got (url, {json :true})
        .then(response => {
            var data = response.body
            data = data.map((item) => {return {
                    type: "scatter",
                    name: item.netId,
                    markerType: "circle",
                    toolTipContent: "<span style=\"color:#28a745 \">{name}<span style=\"color:#8c8c8c \"> <br> attendence: {y}<br>grade: {x}</span>",
                    dataPoints: [
                        { y: item.attendanceCount, x: item.grade>0?item.grade:0, markerSize: 15, color: "rgba(40, 167, 69, 0.3)"}]
                }
            })

            this.setState({ data: data, isLoading: false})
        })
        .catch(error => {"error"});

    }
    render() {
        const gridColor = "#8c8c8c";
        const options = {
            type: "scatter",
            animationEnabled: true,
            showInLegend: true,
			axisY: {
				title:"Attendance Count",
                labelWrap: false,
                titleFontColor:gridColor,
                titleFontWeight: "light",
                tickLength:2,
                interval: 1,
                lineColor: gridColor,
                labelFontColor: gridColor,
				crosshair: {
					enabled: true,
					snapToDataPoint: true
				}
			},
			axisX:{
				title: "Grade",
                minimum: -3,
                maximum: 103,
                titleFontColor:gridColor,
                titleFontWeight: "light",
                interval: 20,
                //interlacedColor: "#f7fff7",
                gridColor: "lightgrey" ,
                lineColor: gridColor,
                labelFontColor: gridColor,
				includeZero: true,
				crosshair: {
					enabled: true,
					snapToDataPoint: true
				}
			},

			data: this.state.data
        }

        return (
        <div >

            <Icon style={{ display: "flex", position:"relative", left:"95%"}} onClick={()=>{this.setState({isLoading: true}); this.getData()}}>refresh</Icon>
            <br/>
            {!this.state.isLoading &&
                <div>
                {this.state.data.length>0 ?
                <CanvasJSChart options = {options}
                    /*onRef={ref => this.chart = ref}*/
                />: <div>No available data</div>
                }
                </div>
            }

        </div>
        );
    }
}

export default ScatterPlot
