import React, { Component } from 'react';
import img from './raw/user-default.png'
import './App.css';
import { HashRouter as Router, Redirect } from 'react-router-dom';
import Route from 'react-router-dom/Route';
import Homepage from './components/Homepage'
import Login from './components/Login'
import Signup from './components/Signup'
import CourseInfo from './components/CourseInfo'
import { Nav, Navbar, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import Profile from './components/Profile'
import Test from './components/Test'
import Notifications from 'react-notify-toast'

class App extends Component {
    handleLogOut() {
        if (localStorage.getItem('userInfo')) {
            localStorage.removeItem('userInfo')
        }

        this.setState({ loggedIn: false })
        console.log(localStorage.getItem('userInfo'))
    }


    handleLogIn() {
        this.setState({ loggedIn: true })
        console.log(localStorage.getItem('userInfo'))
    }

    constructor() {
        super()
        this.state = { loggedIn: localStorage.getItem("userInfo") ? true : false }
        this.handleLogOut = this.handleLogOut.bind(this)
        this.handleLogIn = this.handleLogIn.bind(this)
    }

    render() {

        const loggedInStyle = this.state.loggedIn ? {} : { display: 'none' }
        const loggedOutStyle = !this.state.loggedIn ? {} : { display: 'none' }

        return (
            <Router >
                <Notifications />
                <div>
                    <Navbar bg="success" variant="dark" className="top-nav-bar">
                        <LinkContainer to="/home">
                            <Navbar.Brand><h2>OHTracker</h2></Navbar.Brand>
                        </LinkContainer>

                        <Nav className="mr-auto">
                        </Nav>

                        <Nav>

                            <LinkContainer to="/signup" style={loggedOutStyle}>
                                <Nav.Link>Sign up</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/login">
                                <Nav.Link onClick={() => { if (this.state.loggedIn) this.handleLogOut() }}>{(this.state.loggedIn) ? "Log out" : "Log in"}</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/profile" style={loggedInStyle}>
                                <Nav.Link>
                                    <b>{this.state.loggedIn ? (JSON.parse(localStorage.getItem("userInfo"))).username : ''}</b>
                                </Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/profile" style={{ ...loggedInStyle, "width": "35px", "height": "35px" }}>
                                <Image src={img} roundedCircle />
                            </LinkContainer>

                        </Nav>
                    </Navbar>
                    <Route path='/' exact render={() => {
                        return (
                            <Redirect to="/home" />
                        )
                    }} />

                    <Route path='/home' exact render={() => {
                        if (!localStorage.getItem('userInfo')) return <Redirect exact to="/login" />
                        return (
                            <Homepage />
                        )
                    }} />
                    <Route path='/login' exact render={() => {
                        if (localStorage.getItem('userInfo')) return <Redirect exact to="/home" />
                        return (
                            <Login handleLogIn={this.handleLogIn} />
                        )
                    }} />
                    <Route path='/signup' exact render={() => {
                        return (
                            <Signup />
                        )
                    }} />
                    <Route
                        path="/course/:crn" render={({ match }) => {
                            if (!localStorage.getItem('userInfo')) return <Redirect exact to="/login" />
                            return (
                                <CourseInfo crn={match.params.crn} />)
                        }} />

                    <Route
                        path="/officehour/:id" render={({ match }) => {
                            if (!localStorage.getItem('userInfo')) return <Redirect exact to="/login" />
                            return (<div></div>)
                        }} />

                    <Route
                        path="/profile" render={() => (<Profile />)} />

                    <Route
                        path="/test" render={() => (<Test date={Date()} />)} />
                </div>
            </Router>

        )
    }
}

export default App;
