import React from 'react';
import { Form, Button } from 'react-bootstrap'
import { withRouter } from 'react-router';
import swal from 'sweetalert';
import ValidationMessage from './ValidationMessage'

const INSTRUCTOR = "instructor";
const STUDENT = "student"

const VALID_PATTERN = /^[0-9a-zA-Z]+$/

function lettersAndNumbers(str) {

    if (!str) {
        return false
    }

    return str.match(VALID_PATTERN)
}

class Signup extends React.Component {

    constructor(props) {
        super(props)
        const thisYear = new Date().getFullYear()
        const yearSpan = 10
        const yearList = Array(yearSpan).fill().map((_, idx) => thisYear - idx)

        const departmentList = JSON.parse(JSON.stringify(require('../data/majors.json')))
        departmentList.sort()
        departmentList.push('Other')

        this.state = {
            userInfo: {
                username: null,
                realname: null,
                password: null,
                yearEnroll: null,
                department: null,
                identity: STUDENT
            },
            validation: {
                validated: false,
                netid: {
                    invalid: false,
                    message: ''
                },
                name: {
                    invalid: false,
                    message: ''
                },
                year: {
                    invalid: false,
                    message: ''
                },
                major: {
                    invalid: false,
                    message: ''
                },
            },
            yearList: yearList,
            departmentList: departmentList
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleIdentityChange = this.handleIdentityChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.checkUserExists = this.checkUserExists.bind(this)

        this.initialValidataion = this.initialValidataion.bind(this)
        this.resetValidation = this.resetValidation.bind(this)
    }

    checkUserExists(NetID, identity, callback) {

        var checkExistsUrl = ''

        if (identity === 'student') {
            checkExistsUrl = 'http://ohtracker.web.illinois.edu/api/get_student_info/' +
                '?netid=' + NetID
        } else {
            checkExistsUrl = 'http://ohtracker.web.illinois.edu/api/get_professor_info/' +
                '?netid=' + NetID
        }

        checkExistsUrl = checkExistsUrl.replace(' ', '%20')

        var got = require("got")

        got(checkExistsUrl, { json: true }).then(response => {

            if (callback) {
                callback(response.body.length !== 0)
            }
        }).catch(error => {
            console.log(error)
            swal('Error!', 'There was an error while registering your account!', 'error')
        });
    }

    validation_netIdAlreadyExists() {
        var tempValidation = this.state.validation

        tempValidation.netid.invalid = true
        tempValidation.netid.message = 'That NetID is already in use!'

        tempValidation.validated = true

        this.setState({ validation: tempValidation })
    }

    initialValidataion(NetId, name, year, major) {

        if (name) {
            name = name.replace(' ', '')
        }

        if (lettersAndNumbers(NetId) && lettersAndNumbers(name) && year && major) {
            return true
        }

        var tempValidation = this.state.validation

        if (!lettersAndNumbers(NetId)) {
            tempValidation.netid.invalid = true
            tempValidation.netid.message = 'Please enter a valid NetID!'
        }

        if (!lettersAndNumbers(name)) {
            tempValidation.name.invalid = true
            tempValidation.name.message = 'Please enter a valid name!'
        }

        if (!year) {
            tempValidation.year.invalid = true
            tempValidation.year.message = 'Please select a starting year!'
        }

        if (!major) {
            tempValidation.major.invalid = true
            tempValidation.major.message = 'Please select a major!'
        }

        tempValidation.validated = true

        this.setState({ validation: tempValidation })

        return false
    }

    resetValidation() {
        var tempValidation = this.state.validation

        tempValidation.netid.invalid = false
        tempValidation.name.invalid = false
        tempValidation.year.invalid = false
        tempValidation.major.invalid = false

        tempValidation.validated = false

        this.setState({ validation: tempValidation })

    }

    signUpInDB(NetID, realname, password, yearEnroll, department, identity, callback) {

        this.resetValidation()

        if (!this.initialValidataion(NetID, realname, yearEnroll, department)) {
            return
        }

        this.checkUserExists(NetID, identity, alreadyExists => {
            if (alreadyExists) {

                this.validation_netIdAlreadyExists()

                if (callback) {
                    callback(false)
                }
            } else {
                var url = ""
                if (identity === "student") {
                    url = "http://ohtracker.web.illinois.edu/api/add_student/?netid=" + NetID +
                        "&name=" + realname +
                        "&major=" + department +
                        "&year=" + yearEnroll
                } else {
                    url = "http://ohtracker.web.illinois.edu/api/add_professor/?netid=" + NetID +
                        "&department=" + department +
                        "&name=" + realname
                }

                url = url.replace(' ', '%20')

                console.log(url)

                var got = require('got')

                got(url, { json: true })
                    .then(response => {
                        console.log(response.body)
                        if (callback) {
                            callback(true)
                        }
                    })
                    .catch(error => {
                        if (callback) {
                            callback(false)
                        }
                    });
            }
        });


    }

    handleSubmit() {
        this.signUpInDB(
            this.state.userInfo.username,
            this.state.userInfo.realname,
            this.state.userInfo.password,
            this.state.userInfo.yearEnroll,
            this.state.userInfo.department,
            this.state.userInfo.identity,
            success => {
                if (success) {
                    swal("Success!", "Created new user " + this.state.userInfo.realname, "success")
                    this.props.history.push('/')
                }
            })

    }

    handleIdentityChange(props) {
        this.setState(prevState => {
            return ({
                userInfo: { ...prevState.userInfo, identity: props }
            }
            )
        })
    }

    handleChange(e) {
        const value = e.target.value
        const id = e.target.id.toString()

        this.setState(prevState => {
            return ({
                validation: {
                    ...prevState.validation,
                    validated: false
                }
            })
        })

        if (id === "password")
            this.setState(prevState => {
                return ({
                    userInfo: { ...prevState.userInfo, password: value }
                })
            })
        else if (id === "username")
            this.setState(prevState => {
                return ({
                    userInfo: { ...prevState.userInfo, username: value }
                })
            })
        else if (id === "realname")
            this.setState(prevState => {
                return ({
                    userInfo: { ...prevState.userInfo, realname: value }
                })
            })
        else if (id === "yearEnroll")
            this.setState(prevState => {
                return ({
                    userInfo: { ...prevState.userInfo, yearEnroll: value }
                })
            })
        else if (id === "department")
            this.setState(prevState => {
                return ({
                    userInfo: { ...prevState.userInfo, department: value }
                })
            })
    }


    render(props) {

        let validated = this.state.validation.validated

        let netIdInvalid = validated && this.state.validation.netid.invalid
        let netIdMessage = this.state.validation.netid.message

        let nameInvalid = validated && this.state.validation.name.invalid
        let nameMessage = this.state.validation.name.message

        let yearInvalid = validated && this.state.validation.year.invalid
        let yearMessage = this.state.validation.year.message

        let majorInvalid = validated && this.state.validation.major.invalid
        let majorMessage = this.state.validation.major.message

        return (
            <div>
                <div className='signup-form'>

                    <Form>
                        <Form.Group controlId='username'>
                            <Form.Label>NetID</Form.Label>
                            <ValidationMessage
                                enabled={netIdInvalid}
                                message={netIdMessage}
                            />
                            <Form.Control required type="text" placeholder="Enter NetID" onChange={this.handleChange} />
                        </Form.Group>

                        <Form.Group controlId="realname">
                            <Form.Label>Name</Form.Label>
                            <ValidationMessage
                                enabled={nameInvalid}
                                message={nameMessage}
                            />
                            <Form.Control required type="text" placeholder="Enter your name" onChange={this.handleChange} />
                        </Form.Group>

                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control disabled type="password" placeholder="Password Usage Disabled" onChange={this.handleChange} />
                        </Form.Group>

                        <Form.Group controlId="yearEnroll" style={{
                            "display": (this.state.userInfo.identity === INSTRUCTOR) ? "none" : "block"
                        }}>

                            <Form.Label>Year of enrollment</Form.Label>
                            <ValidationMessage
                                enabled={yearInvalid}
                                message={yearMessage}
                            />
                            <Form.Control as="select" onChange={this.handleChange}>
                                <option key='' selected disabled>Select a year</option>
                                {this.state.yearList.map((year, idx) =>
                                    <option key={idx}>{year}</option>
                                )}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="department" >
                            <Form.Label>Department</Form.Label>
                            <ValidationMessage
                                enabled={majorInvalid}
                                message={majorMessage}
                            />
                            <Form.Control as="select" onChange={this.handleChange}>
                                <option key='' selected disabled>Select a major</option>
                                {this.state.departmentList.map((department, idx) =>
                                    <option key={idx}>{department}</option>
                                )}
                            </Form.Control>
                        </Form.Group>

                        <Form.Label>Identity</Form.Label>
                        <Form.Group controlId="Checkbox">

                            <Form.Check
                                inline
                                id="instructorRadio"
                                label="Instructor"
                                type="radio"
                                checked={this.state.userInfo.identity === INSTRUCTOR}
                                onChange={() => { this.handleIdentityChange(INSTRUCTOR) }}
                            />

                            <Form.Check
                                inline
                                id="studentRadio"
                                label="Student"
                                type="radio"
                                checked={this.state.userInfo.identity === STUDENT}
                                onChange={() => { this.handleIdentityChange(STUDENT) }}
                            />

                        </Form.Group>

                        <Button variant="success" onClick={this.handleSubmit}>
                            Submit
            </Button>
                    </Form>
                </div>
            </div>
        )
    }
}


export default withRouter(Signup)
