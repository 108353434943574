import React from 'react'
import { Form, InputGroup, Button, Alert, Modal } from 'react-bootstrap'
import CourseItem from './CourseItem'
import UnaddedCourseItem from './UnaddedCourseItem'
import swal from 'sweetalert'
import { SyncLoader } from 'react-spinners'
import NewCourseModal from './modals/NewCourseModal'
import StackGrid from "react-stack-grid"
import { notify } from 'react-notify-toast'

function compareCourse(course1, course2) {
    if (course1.Code < course2.Code) {
        return -1;
    } else if (course1.Code > course2.Code) {
        return 1;
    }

    return 0;
}

function courseFilter(course) {
    var filter = this.toLowerCase()

    for (var ind = 0; ind < course.Instructors.length; ind++) {
        if (course.Instructors[ind].toLowerCase().includes(filter)) {
            return true
        }
    }

    return course.CRN.toString().includes(filter) ||
        course.Code.toLowerCase().includes(filter) ||
        course.CourseName.toLowerCase().includes(filter) ||
        course.Section.toLowerCase().includes(filter);
}

function removeAddedCourses(course) {
    var res = this.filter(item => (item.CRN === course.CRN))
    return res.length === 0
}

class Homepage extends React.Component {

    fetchCoursesFromDB(NetID, identity) {

        console.log('Netid and identity:', NetID, identity)

        const got = require('got');

        var url = 'http://ohtracker.web.illinois.edu/api/get_class_card_info/?netid=' + NetID + '&which=' + identity

        console.log('url:', url)

        got(url, { json: true })
            .then(response => {

                var data = response.body

                for (var ind = 0; ind < data.length; ind++) {
                    data[ind].Instructors = [data[ind].Instructors]
                }

                console.log("User's courses:", data)
                this.setState({
                    items: data,
                    filteredItems: data,
                    loading: false
                }, () => {
                    this.updateFilteredCourses()
                    this.updateAllFilteredCourses()
                })
            })
            .catch(error => {
                console.log('Catched error:', error);
                this.setState({
                    items: []
                })
            });

    }

    fetchAllCoursesFromDB(callback) {
        const got = require('got');

        var url = 'http://ohtracker.web.illinois.edu/api/get_all_classes/?a=a'

        console.log('url:', url)

        got(url, { json: true })
            .then(response => {

                var data = response.body

                for (var ind = 0; ind < data.length; ind++) {
                    data[ind].Instructors = [data[ind].Instructors]
                }

                console.log("All courses:", data)
                this.setState({
                    allCourses: data,
                    filteredAllCourses: data,
                    loading: false
                }, () => {
                    this.updateAllFilteredCourses()
                    if (callback) {
                        callback()
                    }
                })
            })
            .catch(error => {
                console.log('Caught error:', error);
            });

    }

    unenrollStudent(NetID, CRN) {
        var got = require('got');
        var url = 'http://ohtracker.web.illinois.edu/api/unenroll/?netid=' + NetID + '&crn=' + CRN;
        console.log('url:' + url);
        got(url, { json: true })
            .then(response => {
                console.log('Response: ', response);
                var userInfo = JSON.parse(localStorage.getItem("userInfo"));
                const identity = userInfo.identity;
                this.fetchCoursesFromDB(NetID, identity);
            })
            .catch(error => {
                console.log('Caught error:', error);
            });
    }

    onRemove(CRN) {
        var userInfo = JSON.parse(localStorage.getItem("userInfo"))
        const NetID = userInfo.username

        swal("Are you sure you want to remove course " + CRN + "?", {
            buttons: ["No", true],
        }).then((value) => {
            if (value) {

                // BACKEND :: Remove from database
                if (userInfo.identity === "instructor") {
                    this.deleteCourse(CRN)
                }
                else {
                    this.unenrollStudent(NetID, CRN)
                }
                // const items = this.state.items.filter(item => (item.CRN != CRN));
                // localStorage.setItem('course-items', JSON.stringify(items))

                // BACKEND :: Codes below are not supposed to be changed
                // this.fetchCoursesFromDB(NetID, identity)
                // window.location.reload();
            }
            else return;

        });
        /*

        */
        //window.location.reload();
    }

    deleteCourse(CRN) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"))
        var got = require('got');
        var url = 'http://ohtracker.web.illinois.edu/api/remove_class/?crn=' + CRN;
        console.log('url:' + url);
        got(url, { json: true })
            .then(response => {
                this.fetchCoursesFromDB(userInfo.username, userInfo.identity);
            })
            .catch(error => {
                console.log('Caught error:', error);
            });
    }

    enrollStudent(NetID, CRN) {
        var got = require('got');
        var url = 'http://ohtracker.web.illinois.edu/api/enroll/?netid=' + NetID + '&crn=' + CRN;
        console.log('url:' + url);
        got(url, { json: true })
            .then(response => {
                console.log('Response: ', response);
                var userInfo = JSON.parse(localStorage.getItem("userInfo"));
                const identity = userInfo.identity;
                this.fetchCoursesFromDB(NetID, identity);
            })
            .catch(error => {
                console.log('Caught error:', error);
            });
    }

    addCourseInDB(CRN) {
        try {
            var userInfo = JSON.parse(localStorage.getItem("userInfo"))
            const NetID = userInfo.username

            // BACKEND :: add a enrollment
            this.enrollStudent(NetID, CRN)
            // var items = this.state.items;
            // items.push({CourseName:"Software Engineering V", CRN:CRN, Instructors:['John Smith','Adam Lambert'], Code:"CS500"})
            // localStorage.setItem('course-items', JSON.stringify(items))

            return true
        } catch (e) {
            return false
        }

    }

    handleAdd(inputCourse = null) {

        const identity = JSON.parse(localStorage.getItem("userInfo")).identity
        if (identity === "instructor") {
            this.setState({
                isAdding: true
            })
            return
        }

        if (inputCourse === null) {

            swal("CRN of the course you want to add:", {
                content: "input",
                buttons: true,
                closeModal: true,
                cancel: {
                    text: "Cancel",
                    value: null,
                    visible: false,
                    className: "",
                    closeModal: true,
                },
            })
                .then((value) => {
                    if (value === null) return
                    const CRN = parseInt(value)
                    if (isNaN(CRN))
                        swal("Error!", "'" + value + "' is not a number!", "error")
                    else if (this.state.items.filter(item => (item.CRN === CRN)).length > 0)
                        swal("Course already added", "You have already added this course!", "info")
                    else {
                        if (this.addCourseInDB(CRN)) {
                            swal("Success!", "Course added: " + CRN, "success")
                        }
                        else swal("Error!", "No course exists with CRN '" + value + "'!", "error")
                    }
                });
        } else {
            
            this.addCourseInDB(inputCourse.CRN)
            this.sendToast(
                <div>
                    <b>{inputCourse.Code}</b> was added successfully!
                </div>
            )
        }

    }

    handleAllCoursesToggle() {

        if (this.state.allCourses.length === 0) {
            this.setState({ loading: true }, () => {
                this.fetchAllCoursesFromDB(() => {
                    this.setState(prevState => ({
                        addFromAllMode: !prevState.addFromAllMode
                    }))
                })
            })
        } else {
            this.setState(prevState => ({
                addFromAllMode: !prevState.addFromAllMode
            }))
        }

    }

    updateFilteredCourses() {

        var filter = this.state.filter

        if (filter === null || filter === '') {
            this.setState({ filteredItems: this.state.items.sort(compareCourse) })
        } else {
            this.setState({
                filteredItems: this.state.items.filter(courseFilter, filter).sort(compareCourse)
            })
        }

    }

    updateAllFilteredCourses() {

        

        var filter = this.state.filter

        if (filter === null || filter === '') {
            this.setState({
                filteredAllCourses: this.state.allCourses.filter(
                    removeAddedCourses, this.state.items
                ).sort(compareCourse)
            })
        } else {
            this.setState({
                filteredAllCourses: this.state.allCourses.filter(
                    courseFilter, filter
                ).filter(
                    removeAddedCourses, this.state.items
                ).sort(compareCourse)
            })
        }
    }

    cancelAdding() {
        this.setState({
            isAdding: false
        })
    }
    updateItem(id, value) {
        this.setState(prevState => {
            return ({
                newCourseInfo: { ...prevState.newCourseInfo, [id]: value }
            }
            )
        })
    }

    confirmAdding() {
        const NetID = JSON.parse(localStorage.getItem("userInfo")).username;
        const identity = JSON.parse(localStorage.getItem("userInfo")).identity;
        var got = require("got")
        var url = "http://ohtracker.web.illinois.edu/api/add_class/?crn=" + this.state.newCourseInfo.newCRN +
            "&section=" + this.state.newCourseInfo.newSection +
            "&department=" + this.state.newCourseInfo.newDepartment +
            "&name=" + this.state.newCourseInfo.newName +
            "&netid=" + NetID +
            "&code=" + this.state.newCourseInfo.newCode

        this.setState({
            isAdding: false
        })

        url.replace(" ", "%20")
        got(url, { json: true })
            .then(response => {
                this.fetchCoursesFromDB(NetID, identity)
            })
            .catch(error => {
                console.log("add course error")
            });
    }

    constructor() {
        super()
        var userInfo = JSON.parse(localStorage.getItem("userInfo"))
        const NetID = userInfo.username
        const identity = userInfo.identity

        this.fetchCoursesFromDB = this.fetchCoursesFromDB.bind(this)
        this.fetchAllCoursesFromDB = this.fetchAllCoursesFromDB.bind(this)

        this.handleAllCoursesToggle = this.handleAllCoursesToggle.bind(this)

        this.unenrollStudent = this.unenrollStudent.bind(this)
        this.enrollStudent = this.enrollStudent.bind(this)
        this.cancelAdding = this.cancelAdding.bind(this)
        this.updateItem = this.updateItem.bind(this)

        this.state = {
            loading: true,

            isAdding: false,
            newCourseInfo: {},

            items: [],
            filteredItems: [],

            addFromAllMode: false,
            allCourses: [],
            filteredAllCourses: [],

            filter: ''
        }

        this.onRemove = this.onRemove.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.addCourseInDB = this.addCourseInDB.bind(this)
        this.updateFilteredCourses = this.updateFilteredCourses.bind(this)
        this.confirmAdding = this.confirmAdding.bind(this)
        this.deleteCourse = this.deleteCourse.bind(this)

        this.toast = notify.createShowQueue()
        this.sendToast = this.sendToast.bind(this)

        this.fetchCoursesFromDB(NetID, identity)


    }

    sendToast(message) {
        let myStyle = {
            background: 'transparent',
        }

        this.toast(
            <Alert variant='warning'>
                {message}
            </Alert>
        , 'custom', 2000, myStyle)
    }

    render() {

        const loadingStyle = this.state.loading ? {} : { display: 'none' }
        const loadedStyle = !this.state.loading ? {} : { display: 'none' }

        const identity = JSON.parse(localStorage.getItem("userInfo")).identity

        let searchAllButton;
        let addCourseButton;

        if (identity !== 'instructor') {
            searchAllButton =
                <InputGroup.Append>
                    <Button
                        className='noOutline'
                        variant={this.state.addFromAllMode ? 'success' : 'outline-success'}
                        onClick={this.handleAllCoursesToggle}>
                        Search All Courses
                    </Button>
                </InputGroup.Append>
            addCourseButton = ''
        } else {
            searchAllButton = ''
            addCourseButton =
                <div className="col-auto pr-0">
                    <Button variant="success" onClick={this.handleAdd} >Add Course</Button>
                </div>
        }

        let courseCards;

        if (this.state.addFromAllMode) {

            if (this.state.allCourses.length === 0) {
                courseCards =
                    <Alert variant='warning' className='mx-2'>
                        <center><b>There are no courses registered!</b></center>
                    </Alert>;
            } else if (this.state.items.length === this.state.allCourses.length) {
                courseCards =
                    <Alert variant='dark' className='mx-2'>
                        <center><i>Why the hell did you add every course!?</i></center>
                    </Alert>;
            } else if (this.state.filteredAllCourses.length === 0) {
                courseCards =
                    <Alert variant='danger' className='mx-2'>
                        <center><b>There are no available courses that match your search!</b></center>
                    </Alert>;
            } else {
                courseCards =
                    <div>
                        <Alert variant='info' className='mx-2 my-0 mb-2'>
                            <center><b>Click on a course to add it!</b></center>
                        </Alert>
                        <div className="course-list px-2 my-0">
                            <StackGrid columnWidth={"33%"}>
                                {this.state.filteredAllCourses.map((item, idx) =>
                                    <UnaddedCourseItem
                                        key={item.CRN}
                                        onClick={this.handleAdd}
                                        item={item}
                                    />)}
                            </StackGrid>
                        </div>
                    </div>;
            }

        } else {
            if (this.state.items.length === 0) {
                courseCards =
                    <Alert variant='warning' className='mx-2'>
                        <center>You do not have any courses added!
                            Click the <b>Search All Courses</b> button to add some!</center>
                    </Alert>;
            } else if (this.state.filteredItems.length === 0) {
                courseCards =
                    <Alert variant='danger' className='mx-2'>
                        <center><b>You do not have any courses that match your search!</b></center>
                    </Alert>;
            } else {
                courseCards =
                    <div className="course-list px-2 my-0">
                        <StackGrid columnWidth={"33%"}>
                            {this.state.filteredItems.map((item, idx) =>
                                <CourseItem
                                    key={item.CRN}
                                    onRemove={this.onRemove}
                                    item={item}
                                />)}
                        </StackGrid>
                    </div>;
            }
        }



        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.isAdding}
                    onHide={this.cancelAdding}>
                    <Modal.Header closeButton>
                        <Modal.Title>Adding a new course</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: 'calc(80vh - 210px)', overflowY: 'auto' }}>
                        <NewCourseModal onUpdate={this.updateItem} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={this.cancelAdding}>
                            Close
                           </Button>
                        <Button variant="success" onClick={this.confirmAdding}>
                            Add
                           </Button>
                    </Modal.Footer>
                </Modal>


                <div className="container-flow p-2 row w-100 m-0">
                    <div className="col px-0">
                        <InputGroup>
                            <Form.Control
                                className='noOutline'
                                type="text"
                                placeholder="Search courses"
                                onChange={event => {

                                    this.setState({ filter: event.target.value }, () => {
                                        this.updateAllFilteredCourses()
                                        this.updateFilteredCourses()
                                    })

                                }}
                            />

                            {searchAllButton}

                        </InputGroup>
                    </div>

                    {addCourseButton}
                </div>

                <div align='center' className='p-3' style={loadingStyle}>
                    <SyncLoader
                        color='#28a745'
                        size={10}
                        margin='10px'
                    />
                </div>

                <div style={loadedStyle}>
                    {courseCards}
                </div>

            </div>
        )
    }
}

export default Homepage
